<template>
    <li class="nav-item dropdown">
        <div class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-l ">
                <img class="rounded-circle " src="../assets/img/team/40x40/57.webp" alt="" />
            </div>
        </div>
        <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
            <div class="card position-relative border-0">
                <div class="card-body p-0">
                    <div class="text-center pt-4 pb-3">
                        <div class="avatar avatar-xl ">
                            <img class="rounded-circle " src="../assets/img/team/72x72/57.webp" alt="" />
                        </div>
                        <h6 class="mt-2 text-black">Jerry Seinfield</h6>
                    </div>
                </div>
                <div class="overflow-auto scrollbar" style="height: 8rem;">
                    <ul class="nav d-flex flex-column mb-2 pb-1">
                        <li class="nav-item"><router-link class="nav-link px-3" to="#!" type="button"> <span class="me-2 text-900" data-feather="user"></span><span>Profile</span></router-link></li>
                        <li class="nav-item"><router-link class="nav-link px-3" to="/dashboard" type="button"><span class="me-2 text-900" data-feather="pie-chart"></span>Dashboard</router-link></li>
                        <li class="nav-item"><router-link class="nav-link px-3" to="#!" type="button"> <span class="me-2 text-900" data-feather="settings"></span>Settings &amp; Privacy </router-link></li>
                        <li class="nav-item"><router-link class="nav-link px-3" to="#!" type="button"> <span class="me-2 text-900" data-feather="help-circle"></span>Help Center</router-link></li>
                    </ul>
                </div>
                <div class="card-footer p-0 border-top">
                    <hr class="profile-hr"/>
                    <div class="px-3"> <button class="btn btn-phoenix-secondary d-flex flex-center w-100" @click="logout"> <span class="me-2" data-feather="log-out"> </span>Sign out</button></div>
                    <div class="my-2 text-center fw-bold fs--2 text-600"><a class="text-600 me-1" href="#!">Privacy policy</a>&bull;<a class="text-600 mx-1" href="#!">Terms</a>&bull;<a class="text-600 ms-1" href="#!">Cookies</a></div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import authService from '@/services/authService';


    export default {
        name: 'UserProfile',
        methods: {
            async logout() {
                try {
                    authService.logout();
                    this.$router.push('/auth');
                } catch (error) {
                    this.error = error.message;
                }
            },
        },
    };
</script>

<style scoped>
  
</style>