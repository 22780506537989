<template>
    <div ref="kanbanRoot" class="scrollbar" data-kanban-container="data-kanban-container" @mousemove="checkMousePosition">
        <div class="deals">
            <kanban-column v-for="(stage) in leadStages"
                           :title="stage.title"
                           :forecastRevenue="stage.forecastRevenue" />

            <div id="dropZones" :class="['drop-zones', { show: showDropZones }]">
                <div class="drop-zone junk" ref="junkZone" data-dropzone="data-data-dropzone" id="drop-zone-junk" data-stage-name="Junk">
                    <h5 class="text-white"><font-awesome-icon :icon="['fas', 'trash-can']" style="margin-right: 7px;" />Junk</h5>
                </div>
                <div class="drop-zone success" ref="successZone" data-dropzone="data-data-dropzone" id="drop-zone-success" data-stage-name="Success">
                    <h5 class="text-white"><font-awesome-icon :icon="['fas', 'thumbs-up']" style="margin-right: 7px;" />Success</h5>
                </div>
            </div>

            <!--Let it be here for now, it will come in handy in the future.-->
            <!--
                New Stage block
                <div class="deals-col position-relative">
                      <div class="d-flex flex-center flex-column h-100">
                          <h3 class="mb-4">Add new stage</h3>
                          <button class="btn btn-sm btn-primary"><span class="fa-solid fa-plus me-2"></span>New Stage</button>
                      </div>
                  </div>
            -->

        </div>
    </div>

    <div id="scroll-left" @mousemove="scroll('left')" @mouseleave="stopScrolling" v-show="showLeftArrow"><font-awesome-icon :icon="['fas', 'circle-arrow-left']" size="4x" /></div>
    <div id="scroll-right" @mousemove="scroll('right')" @mouseleave="stopScrolling" v-show="showRightArrow"><font-awesome-icon :icon="['fas', 'circle-arrow-right']" size="4x" /></div>

</template>

<script>
    import { onMounted, ref, reactive, toRefs } from 'vue';
    import KanbanColumn from './KanbanColumn.vue';

    import emitter from '@/eventBus';

    export default {
        name: 'Kanban',
        components: {
            KanbanColumn
        },
        setup(_, { emit }) {
            const kanbanRoot = ref(null);

            const state = reactive({
                showDropZones: false,
            });

            const toggleDropZones = (status) => {
                state.showDropZones = status;
            };

            onMounted(() => {
                if (kanbanRoot.value) {
                    // emit an event with the root DOM element
                    emit('mounted', kanbanRoot.value);
                }

                emitter.on('card-move-started', () => {
                    toggleDropZones(true);
                });

                emitter.on('card-move-ended', () => {
                    toggleDropZones(false);
                });

                emitter.on('card-move-hover-dropzone-junk', () => {
                });

                emitter.on('card-move-hover-dropzone-success', () => {
                });
            });

            return {
                ...toRefs(state),
                kanbanRoot
            }
        },
        mounted() {
            document.addEventListener('mousemove', this.checkScroll);
        },
        methods: {
            checkScroll(e) {
                const buffer = 20; // adjust this to change the distance from edge for arrow to appear
                this.showLeftArrow = e.pageX < buffer;
                this.showRightArrow = e.pageX > window.innerWidth - buffer;
            },
            scroll(direction) {
                const scrollAmount = 15; // adjust this to change the speed of scrolling
                const container = this.$refs.kanbanRoot;

                const performScroll = () => {
                    if (this.isScrolling) {
                        if (direction === 'right') {
                            container.scrollLeft += scrollAmount;
                        } else if (direction === 'left') {
                            container.scrollLeft -= scrollAmount;
                        }

                        window.requestAnimationFrame(performScroll);
                    }
                };

                if (!this.isScrolling) {
                    this.isScrolling = true;
                    performScroll();
                }
            },
            stopScrolling() {
                this.isScrolling = false;
            },
            checkMousePosition(e) {
                const buffer = 100;  // distance from edge to start showing the arrows
                this.showArrows = e.clientX < buffer || e.clientX > window.innerWidth - buffer;
            },
        },
        data() {
            // Temporary static collection of lead stages
            return {
                showLeftArrow: false,
                showRightArrow: false,
                isScrolling: false,
                leadStages: [
                    {
                        id: 1,
                        title: 'New',
                        forecastRevenue: '$0',
                        color: 'bg-soft-primary'
                    },
                    {
                        id: 2,
                        title: 'Preliminary qualification',
                        forecastRevenue: '$0',
                        color: 'bg-soft-warning'
                    },
                    {
                        id: 3,
                        title: 'Content Engagement',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                    {
                        id: 4,
                        title: 'Invitation to the network',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                    {
                        id: 5,
                        title: 'Invited to the network',
                        forecastRevenue: '$0',
                        color: 'bg-soft-danger'
                    },
                    {
                        id: 6,
                        title: 'Helpful Content - Case Study',
                        forecastRevenue: '$0',
                        color: 'bg-soft-danger'
                    },
                    {
                        id: 7,
                        title: 'Helpful Content - Blog Post',
                        forecastRevenue: '$0',
                        color: 'bg-soft-primary'
                    },
                    {
                        id: 8,
                        title: 'Free Trial Offer',
                        forecastRevenue: '$0',
                        color: 'bg-soft-warning'
                    },
                    {
                        id: 9,
                        title: 'Free Trial Reminder',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                    {
                        id: 10,
                        title: 'Free Trial Form Applied',
                        forecastRevenue: '$0',
                        color: 'bg-soft-danger'
                    },
                    {
                        id: 11,
                        title: 'Follow Up Questionary',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                    {
                        id: 12,
                        title: 'Arranged meeting',
                        forecastRevenue: '$0',
                        color: 'bg-soft-danger'
                    },
                    {
                        id: 13,
                        title: 'User rating request',
                        forecastRevenue: '$0',
                        color: 'bg-soft-primary'
                    },
                    {
                        id: 14,
                        title: 'Awareness Ads',
                        forecastRevenue: '$0',
                        color: 'bg-soft-warning'
                    },
                    {
                        id: 15,
                        title: 'Engagement Ads',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                    {
                        id: 16,
                        title: 'Selling Ads With Apply Form',
                        forecastRevenue: '$0',
                        color: 'bg-soft-danger'
                    },
                    {
                        id: 17,
                        title: 'Proposal',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    },
                /*    {
                        id: 18,
                        title: 'Success',
                        forecastRevenue: '$0',
                        color: 'bg-soft-success'
                    }*/
                ]
            }
        },
          beforeDestroy() {
            document.removeEventListener('mousemove', this.checkScroll);
        }
    };
</script>

<style scoped>
</style>