import { createStore } from 'vuex';

export default createStore({
    state: {
        accessToken: localStorage.getItem('accessToken') || null,
        intendedRoute: '/',
        // Add new state property for storing pagination state for each stage
        kanbanPageStates: {},
        leadsStageCounter: {},
        // other state properties
    },
    getters: {
        getAccessToken: (state) => state.accessToken,
        getIntendedRoute: (state) => state.intendedRoute,
        // Add getter for retrieving the page state for a specific stage
        getKanbanPageState: (state) => (stage) => {
            return state.kanbanPageStates[stage] || 1; // default page is 1 if not exist
        },
        getLeadsStageCounter: (state) => (stage) => {
            if (!state.leadsStageCounter) {
                return 0;
            }
            return state.leadsStageCounter[stage] || 0; // default counter is 0 if not exist
        },
        // other getters
    },
    mutations: {
        setAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        clearAccessToken: (state) => {
            state.accessToken = null;
        },
        setIntendedRoute: (state, intendedRoute) => {
            state.intendedRoute = intendedRoute;
        },
        clearIntendedRoute: (state) => {
            state.intendedRoute = null;
        },
        // Add mutation for updating the page state for a specific stage
        setKanbanPageState: (state, { stage, page }) => {
            state.kanbanPageStates = { ...state.kanbanPageStates, [stage]: page };
        },
        setLeadsStageCounter(state, { stage, count }) {
            state.leadsStageCounter = { ...state.leadsStageCounter, [stage]: count };
        },
        // other mutations
    },
    // actions and modules
});