import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

// Import services
import authService from '@/services/authService';

// Import your components
import Auth from './components/Auth.vue';
import Dashboard from './components/Dashboard.vue';
import Leads from './components/Leads.vue';
import SignUp from './components/SignUp.vue';
import PasswordRecovery from './components/PasswordRecovery.vue';
import ResetPassword from './components/ResetPassword.vue';
import Integrations from './components/Integrations.vue';

const routes = [
    {
        path: '/',
        redirect: { name: 'Dashboard' },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/leads',
        name: 'Leads',
        component: Leads,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/integrations',
        name: 'Integrations',
        component: Integrations,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!authService.isAuthenticated()) {
            // Save the intended route
            store.commit('setIntendedRoute', to.fullPath);
            next({ name: 'Auth' });
        } else {
            next();
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
});

export default router;