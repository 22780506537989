<template>
    <div class="col-sm-6 col-md-6 col-xl-6 col-xxl-6" :class="compareClass">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex d-sm-block justify-content-between">
                    <div class="border-bottom-sm mb-sm-4">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center icon-wrapper-sm shadow-primary-100" style="transform: rotate(-7.45deg);"><span class="fa-solid fa-phone-alt text-primary fs-1 z-index-1 ms-2"><font-awesome-icon :icon="['fas', faIconCode ]" /></span></div>
                            <p class="text-700 fs--1 mb-0 ms-2 mt-3">{{ headerMessage }}</p>
                        </div>
                        <p class="text-primary mt-2 fs-2 fw-bold mb-0 mb-sm-4"><span class="text-primary" style="padding-right:5px;" v-if="loading"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span><span style="padding-right: 5px;" v-if="!loading">{{ mainCounter }}</span><span class="fs-0 text-900 lh-lg">{{ mainText }}</span></p>
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-between-end d-sm-block text-end text-sm-start">
                        <span class="badge badge-phoenix fs--2 mb-2" :class="compareColorClass">{{ comparePercentage }}</span>
                        <p class="mb-0 fs--1 text-700">{{ compareText }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = 'https://api.cleverup.io';

    export default {
        name: 'DashboardCounterCard',
        props: {
            faIconCode: {
                type: String,
                required: true
            },
            headerMessage: {
                type: String,
                required: true
            },
            mainNumber: {
                type: String,
                required: true
            },
            mainText: {
                type: String,
                required: true
            },
            compareColorClass: {
                type: String,
                required: true
            },
            comparePercentage: {
                type: String,
                required: true
            },
            compareText: {
                type: String,
                required: true
            },
            compareClass: {
                type: String,
                required: true
            },
            compareType: {
                type: String,
                required: true
            },
        },
        data () {
            return {
                mainCounter: 0,
                loading: false,
            }
        },
        mounted() {
            if (this.compareType === 'invites_sent') {
                this.fetchLeadInvites();
            }
        },
        methods: {
            async fetchLeadInvites() {
                return new Promise((resolve, reject) => {
                    const token = localStorage.getItem('accessToken');
                    const today = new Date();

                    this.loading = true;
               
                    axios.post(`${API_BASE_URL}/api/leads/history/get`, {
                        stage: 'Invitation to the network',
                        date: today
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {

                            this.loading = false;

                            if (response.data.error) {
                            }
                            else {
                                this.mainCounter = this.getUniqueLeadCount(response.data); 
                            }
                        })
                        .catch(error => {
                            console.error('There was an error getting the kanban data:', error);
                        });
                    resolve();
                });
            },
            getUniqueLeadCount(leadsObj) {
                const leads = leadsObj.leads;
                if (Array.isArray(leads)) {
                    const uniqueLeadIds = new Set(leads.map(lead => lead.id));
                    return uniqueLeadIds.size;
                }
                return 0;
            }
        },
    }
</script>

<style scoped>
</style>