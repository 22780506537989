<template>
    <div class="deals-col me-2">
        <div class="d-flex align-items-center justify-content-between position-sticky top-0 z-index-1 bg-soft kanban-column-head">
            <div>
                <h5 class="mb-2">{{title}} <small class="text-muted">({{currentCounter}})</small></h5>
                <div class="d-flex gap-3 justify-content-between kanban-columnn-head-content">
                    <div>
                        <p class="fs--1 text-700 mb-1">Forecast Revenue:</p>
                        <h4 class="mb-3">{{forecastRevenue}}</h4>
                    </div>
                    <div>
                        <button class="btn p-0" style="margin-right: 5px;" type="button" data-bs-toggle="modal" data-bs-target="#addDealModal" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fa-solid fa-plus"><font-awesome-icon :icon="['fas', 'fa-plus']" /></span></button>
                        <button class="btn p-0"> <span class="fa-solid fa-ellipsis"><font-awesome-icon :icon="['fas', 'fa-ellipsis']" /></span></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader text-500" v-if="isGlobalLoading"><font-awesome-icon :icon="['fas', 'spinner']" spin/></div>
        <div class="scrollbar deals-items-container" @scroll="checkScroll" ref="scrollContainer">
            <div class="w-100 min-vh-50" data-sortable="data-sortable" :data-stage-name="title">
                <kanban-card v-for="(card, index) in kanbanData" :key="index" :card="card"></kanban-card>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapMutations, mapGetters } from 'vuex';
    import KanbanCard from './KanbanCard.vue';
    import axios from 'axios';

    import emitter from '@/eventBus';

    const API_BASE_URL = 'https://api.cleverup.io';

    export default defineComponent({
        name: 'KanbanColumn',
        components: {
            KanbanCard,
        },
        props: {
            title: {
                type: String,
                default: 'New'
            },
            forecastRevenue: {
                type: String,
                default: '$37,000.00'
            },
            color: {
                type: String,
                default: 'bg-soft-primary'
            }
        },
        data() {
            return {
                isLoading: false,
                kanbanData: [],
                searchTerm: '',
                afterSearch: false,
                isGlobalLoading: true,
            }
        },
        computed: {
            ...mapGetters(['getKanbanPageState', 'getLeadsStageCounter']), // map the getter to a computed property
            currentPage: {
                get() {
                    return this.getKanbanPageState(this.title);
                },
                set(value) {
                    this.setKanbanPageState({ stage: this.title, page: value });
                }
            },
            currentCounter: {
                get() {
                    return this.getLeadsStageCounter(this.title);
                },
                set(value) {
                    this.setLeadsStageCounter({ stage: this.title, page: value });
                }
            },
        },
        methods: {
            ...mapMutations(['setKanbanPageState', 'setLeadsStageCounter']), // map the mutation to a method
            getKanbanData(page) {
                return new Promise((resolve, reject) => {
                    const token = localStorage.getItem('accessToken');
                    const pageSize = 8;

                    if (page === 1) {
                        this.isGlobalLoading = true;
                    }

                    axios.post(`${API_BASE_URL}/api/kanban/get`, {
                        stage: this.title,
                        page: page,
                        pageSize: pageSize,
                        searchTerm: this.searchTerm
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            if (response.data.error) {
                                if (page === 1) {
                                    if (this.searchTerm) {
                                        this.kanbanData = [];
                                        this.setLeadsStageCounter({ stage: this.title, count: 0 });
                                    }
                                    else {
                                        // Do nothing
                                    }
                                }
                            }
                            else {
                                if (this.searchTerm) {
                                    if (page === 1) {
                                        this.kanbanData = response.data.kanbanData;
                                    }
                                    else {
                                        this.kanbanData = this.kanbanData.concat(response.data.kanbanData);
                                    }
                                }
                                else {
                                    if (this.afterSearch == true) {
                                        this.kanbanData = response.data.kanbanData;
                                        this.afterSearch = false;
                                    }
                                    else {
                                        // Append the new data to the existing data
                                        this.kanbanData = this.kanbanData.concat(response.data.kanbanData);
                                    }
                                }
                                this.setLeadsStageCounter({ stage: this.title, count: response.data.totalCount });
                            }
                            this.isGlobalLoading = false;
                        })
                        .catch(error => {
                            console.error('There was an error getting the kanban data:', error);
                        });
                    resolve();
                });
            },
            checkScroll() {
                // Check if the scroll has reached near bottom
                const container = this.$refs.scrollContainer;
                const tolerance = 100;  // Adjust as needed
                if (container.scrollHeight - (container.scrollTop + container.clientHeight) < tolerance) {
                    // Prevent multiple requests in parallel
                    if (!this.isLoading) {
                        this.isLoading = true;
                        this.currentPage++;
                        this.getKanbanData(this.currentPage).then(() => {
                            this.setKanbanPageState({ stage: this.title, page: this.currentPage });
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 300);
                        });
                    }
                }
            }
        },
        mounted() {
            this.getKanbanData(this.currentPage);

            emitter.on('card-moved', (stages) => {
                if (this.searchTerm) {
                    // Do nothing
                }
                else {
                    if (stages.old === this.title) {
                            // Get elements count in the old stage from DOM - <div class="w-100 min-vh-50" data-sortable="data-sortable" data-stage-name="New">
                            const oldStageElementsCount = document.querySelectorAll(`[data-sortable="data-sortable"][data-stage-name="${stages.old}"] .kanban-card`).length;

                            if (oldStageElementsCount < 8) {
                                // Prevent multiple requests in parallel
                                if (!this.isLoading) {
                                    this.isLoading = true;
                                    this.currentPage++;
                                    this.getKanbanData(this.currentPage).then(() => {
                                        this.setKanbanPageState({ stage: this.title, page: this.currentPage });
                                        setTimeout(() => {
                                            this.isLoading = false;
                                        }, 300);
                                    });
                                }
                            }
                    }
                }
            });

            emitter.on('search', searchTerm => {
                this.searchTerm = searchTerm;
                this.setLeadsStageCounter({ stage: this.title, count: 0 });
                this.kanbanData = [];
                this.currentPage = 1;

                if (this.searchTerm.length >= 3) {
                    this.getKanbanData(this.currentPage);
                }
                else if (this.searchTerm.length === 0) {
                    this.afterSearch = true;
                    this.getKanbanData(this.currentPage);
                }
                else {
                    this.currentPage = 1;
                }
            });
        },
        beforeUnmount() {
            this.setKanbanPageState({ stage: this.title, page: this.currentPage });
            emitter.off('card-moved');
            emitter.off('search');
        },
    });
</script>

<style scoped>
</style>
