<template>
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <div class="content">
        <!--    <div class="row gy-3 mb-4 justify-content-between">-->
        <div class="mx-lg-n4 pt-3">
            <div class="row g-3 mb-2 mt-n7">
                <div class="col-xxl-6">
                    <h3>Dashboard</h3>
                    <p class="text-700 mb-3">Check your business growth in one place</p>
                        <div class="row g-6 mb-3" style="height: 396px;">

                            <dashboard-counter-card :faIconCode="faIconCode1" :headerMessage="headerMessage1" :mainNumber="mainNumber1" :mainText="mainText1" :compareColorClass="compareColorClass1" :comparePercentage="comparePercentage1" :compareText="compareText1" :compareClass="leftCard" :compareType="type1"></dashboard-counter-card>

                            <dashboard-counter-card :faIconCode="faIconCode2" :headerMessage="headerMessage2" :mainNumber="mainNumber2" :mainText="mainText2" :compareColorClass="compareColorClass2" :comparePercentage="comparePercentage2" :compareText="compareText2" :compareClass="rightCard" :compareType="type2"></dashboard-counter-card>

                        </div>
                </div>
                <!--  <div class="col-xxl-6 mb-6">
                <h3>Contacts Created</h3>
                <v-chart :option="funnelOptions" :auto-resize="true" />
            </div>-->
                <!--<div class="col-xxl-6 mb-6">
            <div class="card h-100">
                <div class="card-body">
                    <h3>Lead Conversion</h3>
                    <p class="text-700 mb-0">Stages of leads &amp; conversion</p>-->
                <!-- <div class="echart-lead-conversion" style="min-height: 250px;" data-echart-responsive="data-echart-responsive"></div>-->
                <!--<v-chart :option="barFunnelOptionsNew" :auto-resize="true" />
                    </div>
                </div>
            </div>-->

                <div class="col-12 col-xxl-6 mb-6">
                    <div class="mb-3">
                        <h3>New Connections &amp; Replyes</h3>
                        <p class="text-700 mb-0">The pulse of your campaign</p>
                    </div>
                    <div class="row g-6">
                        <div class="col-md-6 mb-2 mb-sm-0 left-card">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <span class="me-2 text-info" data-feather="users" style="min-height:24px; width:24px"><font-awesome-icon class="text-primary" :icon="['fas', 'user-plus']" /></span>
                                        <h5 class="text-600 mb-0">Connects: <span class="text-1100"> 42</span></h5><span class="badge badge-phoenix badge-phoenix-success" style="margin-left:10px;">+24.5%</span>
                                    </div>
                                    <v-chart :option="graphNewConnections" autoresize class="pt-4 pb-0" style="height:250px;" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 right-card">
                            <div class="card h-100">
                                <div class="card-body">
                                        <div class="d-flex align-items-center pr-5">
                                            <span class="me-2 text-primary" data-feather="zap" style="height:24px; width:24px"><font-awesome-icon class="text-primary" :icon="['fas', 'reply']" /></span>
                                            <h5 class="text-600 mb-0">Replyes: <span class="text-1100">102</span></h5><span class="badge badge-phoenix badge-phoenix-success" style="margin-left:10px;">+32.1%</span>
                                        </div>
                                        <v-chart :option="graphNewConnections1" autoresize class="pt-4 pb-0" style="height:250px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
        </div>


        <div class="mx-lg-n4">
            <div class="row g-3 mb-6 mt-n7">
                <div class="col-xl-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h3>Lead Conversion</h3>
                            <p class="text-700 mb-0">Stages of leads &amp; conversion</p>
                            <v-chart style="height: 300px;" :option="barFunnelOptionsNew" autoresize />
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h3>Advertising Effeciency</h3>
                            <p class="text-700">Ads types & engagement</p>
                            <v-chart style="height: 300px; width: 100%;" :option="graphAdsAnalytics" autoresize />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
</template>

<script>
    import LeadConversionChart from './LeadConversionChart.vue';
    import DashboardCounterCard from './DashboardCounterCard.vue';

    const getPastDates = duration => {
        let days;

        switch (duration) {
            case 'week':
                days = 7;
                break;
            case 'month':
                days = 30;
                break;
            case 'year':
                days = 365;
                break;

            default:
                days = duration;
        }
    }

    // const dates = getPastDates(11);
    // console.log(dates);

    const dates = ['11 Apr, 23', '12 Apr, 23', '13 Apr, 23', '14 Apr, 23', '15 Apr, 23', '16 Apr, 23', '17 Apr, 23', '18 Apr, 23', '19 Apr, 23', '20 Apr, 23', '21 Apr, 23', '22 Apr, 23'];
    const dates1 = ['7 Apr, 23', '8 Apr, 23', '9 Apr, 23', '10 Apr, 23', '11 Apr, 23', '12 Apr, 23', '13 Apr, 23', '14 Apr, 23', '15 Apr, 23', '16 Apr, 23', '17 Apr, 23', '18 Apr, 23', '19 Apr, 23', '20 Apr, 23', '21 Apr, 23', '22 Apr, 23'];

    export default {
        name: 'Dashboard',
        components: {
            LeadConversionChart,
            DashboardCounterCard
        },
        data() {
            return {

                faIconCode1: 'handshake',
                headerMessage1: 'Today sent',
                mainNumber1: '0',
                mainText1: 'Invites',
                compareColorClass1: 'badge-phoenix-success',
                comparePercentage1: '+24.3%',
                compareText1: 'Than yesterday',
                leftCard: 'left-card',
                type1: 'invites_sent',

                faIconCode2: 'comments',
                headerMessage2: 'Today sent',
                mainNumber2: '48',
                mainText2: 'Messages',
                compareColorClass2: 'badge-phoenix-warning',
                comparePercentage2: '-4.5%',
                compareText2: 'Than last week',
                rightCard: 'right-card',
                type2: 'messages_sent',

                chartOptions: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: 'Sales',
                            type: 'bar',
                            data: [120, 200, 150, 80, 70, 110, 130],
                        },
                    ],
                },

                funnelOptions: {
                    title: {
                        text: 'Funnel'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c}%'
                    },
                    toolbox: {
                        feature: {
                            dataView: { readOnly: false },
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    legend: {
                        data: ['New', 'Preliminary qualification', 'Invitation to the network', 'Invited to the network', 'Content Engagement']
                    },
                    series: [
                        {
                            name: 'Funnel',
                            type: 'funnel',
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            min: 0,
                            max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'descending',
                            gap: 2,
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            labelLine: {
                                length: 10,
                                lineStyle: {
                                    width: 1,
                                    type: 'solid'
                                }
                            },
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 1
                            },
                            emphasis: {
                                label: {
                                    fontSize: 20
                                }
                            },
                            data: [
                                { value: 5, name: 'Proposal' },
                                { value: 7, name: 'Selling Ads With Apply Form' },
                                { value: 12, name: 'Engagement Ads' },
                                { value: 20, name: 'Awareness Ads' },
                                { value: 45, name: 'User rating request' },
                                { value: 50, name: 'Arranged meeting' },
                                { value: 55, name: 'Follow Up Questionary' },
                                { value: 60, name: 'Free Trial Form Applied' },
                                { value: 65, name: 'Free Trial Reminder' },
                                { value: 70, name: 'Free Trial Offer' },
                                { value: 75, name: 'Helpful Content - Blog Post' },
                                { value: 80, name: 'Helpful Content - Case Study' },
                                { value: 85, name: 'Invited to the network' },
                                { value: 90, name: 'Invitation to the network' },
                                { value: 95, name: 'Preliminary qualification' },
                                { value: 100, name: 'New' }
                            ]
                        }
                    ]
                },

                barFunnelOptions: this.getChartOptions(),
                barFunnelOptionsNew: this.getChartOptionsNew(),
                graphNewConnections: this.getNewConnections(),
                graphNewConnections1: this.getNewConnections1(),
                graphAdsAnalytics: this.getAdsAnalytics()
               
            };
        },
        methods: {
            getChartOptions() {
                return {
                    color: ['#3532A7', '#C0C0C0'],
                    tooltip: {
                        trigger: 'axis',
                        padding: [7, 10],
                        backgroundColor: '#F5F5F5',
                        borderColor: '#C0C0C0',
                        textStyle: { color: '#333333' },
                        borderWidth: 1,
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'none',
                        },
                        // Add your formatter function here
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            interval: 3,
                            showMinLabel: true,
                            showMaxLabel: false,
                            color: '#808080',
                            align: 'left',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            fontSize: 12.8,
                            margin: 10,
                            formatter: (value) => `${value / 1000}k`,
                        },
                        show: true,
                        axisLine: {
                            lineStyle: {
                                color: '#C0C0C0',
                            },
                        },
                        axisTick: false,
                        splitLine: {
                            show: false,
                        },
                    },
                    yAxis: {
                        data: ['Luxemburg', 'Canada', 'Australia', 'India'],
                        type: 'category',
                        axisPointer: { type: 'none' },
                        axisTick: 'none',
                        splitLine: {
                            interval: 5,
                            lineStyle: {
                                color: '#DCDCDC',
                            },
                        },
                        axisLine: { show: false },
                        axisLabel: {
                            show: true,
                            margin: 21,
                            color: '#333333',
                        },
                    },
                    series: [
                        {
                            name: 'Target',
                            type: 'bar',
                            label: {
                                show: false,
                            },
                            emphasis: {
                                disabled: true,
                            },
                            showBackground: true,
                            backgroundStyle: {
                                color: '#F5F5F5',
                            },
                            barWidth: '30px',
                            barGap: '-100%',
                            data: data1,
                            itemStyle: {
                                borderWidth: 4,
                                color: '#DCDCDC',
                                borderColor: '#DCDCDC',
                            },
                        },
                        {
                            name: 'Gained',
                            type: 'bar',
                            emphasis: {
                                disabled: true,
                            },
                            label: {
                                show: true,
                                color: '#FFFFFF',
                                fontWeight: 700,
                                fontFamily: 'Nunito Sans',
                                fontSize: 12.8,
                            },
                            // showBackground: true,
                            backgroundStyle: {
                                color: '#F5F5F5'
                            },
                            barWidth: '30px',
                            data: data2,
                            itemStyle: {
                                borderWidth: 4,
                                color: '#4C48C1',
                                borderColor: '#E0E0E0'
                            }
                        }
                    ],
                    grid: {
                        right: 0,
                        left: 0,
                        bottom: 8,
                        top: 0,
                        containLabel: true
                    },
                    animation: false
                };
            },

            getChartOptionsNew() {
                return {
                    color: ['#3532A7', '#C0C0C0'],
                    tooltip: {
                        trigger: 'axis',
                        padding: [7, 10],
                        backgroundColor: '#F5F5F5',
                        borderColor: '#C0C0C0',
                        textStyle: { color: getColor('dark') },
                        borderWidth: 1,
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'none'
                        },
                        //formatter: params => tooltipFormatter(params)
                    },
                    xAxis: {
                        type: 'value',
                        inverse: true,
                        axisLabel: {
                            show: false
                        },
                        show: false,
                        data: dates,
                        axisLine: {
                            lineStyle: {
                                color: '#C0C0C0'
                            }
                        },
                        axisTick: false
                    },
                    yAxis: {
                        //data: ['Closed Won', 'Objection', 'Offer', 'Qualify Lead', 'Created'],
                        //data: ['Success', 'Apply Form Ad', 'Engagement Ad', 'Awareness Ad', 'User rating request', 'Arranged meeting', 'Follow Up Questionary', 'Free Trial Form Applied', 'Free Trial Reminder', 'Free Trial Offer', 'Helpful Content - Blog Post', 'Helpful Content - Case Study', 'Invited to the network', 'Invitation to the network', 'Preliminary qualification', 'New'],
                        data: ['Success', 'Arranged meeting', 'Follow up questionary', 'Free trial offer', 'Invited to the network','Total leads'],
                        type: 'category',
                        axisPointer: { type: 'none' },
                        axisTick: 'none',
                        splitLine: {
                            interval: 5,
                            lineStyle: {
                                color: '#DCDCDC'
                            }
                        },
                        axisLine: { show: false },
                        axisLabel: {
                            show: true,
                            align: 'left',
                            /*margin: 200,
                            color: '#333333',
                            fontSize: 16,*/
                            margin: 200,
                            color: getColor('gray-900'),
                            fontWeight: 600,
                            fontSize: 16,
                            fontFamily: 'Nunito Sans',
  
                        }
                    },
                    series: {
                        name: 'Lead Conversion',
                        type: 'bar',
                        barWidth: '20px',
                        showBackground: true,
                        data: [
                           /* {
                                value: 750,
                                itemStyle: {
                                    color: getColor('success-100'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('success-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 40% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 40%}`,
                                    rich: {
                                        b: {
                                            color: getColor('success-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },
                            {
                                value: 800,
                                itemStyle: {
                                    color: getColor('success-200'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('success-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 40% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 40%}`,
                                    rich: {
                                        b: {
                                            color: getColor('success-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/
                     /*       {
                                value: 850,
                                itemStyle: {
                                    color: getColor('success-300'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('success-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 45% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 45%}`,
                                    rich: {
                                        b: {
                                            color: getColor('success-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/
                            {
                                value: 900,
                                itemStyle: {
                                    color: '#3532A7E6',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: () => `{b|49%}`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|49%}`,
                                    rich: {
                                        b: {
                                            color: getColor('white'),
                                            fontWeight: 600,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },
                      /*      {
                                value: 950,
                                itemStyle: {
                                    color: getColor('info-100'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('info-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 50% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 50%}`,
                                    rich: {
                                        b: {
                                            color: getColor('info-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },
                            {
                                value: 1000,
                                itemStyle: {
                                    color: getColor('info-200'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('info-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 55% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 55%}`,
                                    rich: {
                                        b: {
                                            color: getColor('info-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/
                            {
                                value: 1100,
                                itemStyle: {
                                    color: '#3532A7D9',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: () => `{b|60%}`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|60%}`,
                                    rich: {
                                        b: {
                                            color: getColor('white'),
                                            fontWeight: 600,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },
                       /*     {
                                value: 1200,
                                itemStyle: {
                                    color: getColor('primary-100'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('primary-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 74% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 74% }`,
                                    rich: {
                                        b: {
                                            color: getColor('primary-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },
                            {
                                value: 1300,
                                itemStyle: {
                                    color: getColor('primary-200'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('primary-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 76% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b| 76% }`,
                                    rich: {
                                        b: {
                                            color: getColor('primary-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/

                            {
                                value: 1400,
                                itemStyle: {
                                    color: '#3532A7CC',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: () => `{b|80%}`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|80%}`,
                                    rich: {
                                        b: {
                                            color: getColor('white'),
                                            fontWeight: 600,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },

          /*                  {
                                value: 1500,
                                itemStyle: {
                                    color: getColor('warning-100'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('warning-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 85% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|85%}`,
                                    rich: {
                                        b: {
                                            color: getColor('warning-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/

                        /*    {
                                value: 1600,
                                itemStyle: {
                                    color: getColor('warning-200'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('warning-300')
                                    },
                                    label: {
                                        formatter: () => `{b| 88% }`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|88%}`,
                                    rich: {
                                        b: {
                                            color: getColor('warning-600'),
                                            fontWeight: 500,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },*/

                            {
                                value: 1700,
                                itemStyle: {
                                    //color: getColor('warning-300'),
                                    color: '#3532A7BF',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: () => `{b|90%}`,
                                        rich: {
                                            b: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|90%}`,
                                    rich: {
                                        b: {
                                            color: getColor('white'),
                                            fontWeight: 600,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },





                            {
                                value: 2100,
                                itemStyle: {
                                    //color: getColor('warning-300'),
                                    color: '#3532A7B3',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: () => `{b|94%}`,
                                        rich: {
                                            b: {
                                                color: getColor('white'),
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|94%}`,
                                    rich: {
                                        b: {
                                            color: getColor('white'),
                                            fontWeight: 600,
                                            padding: [0, 5, 0, 0]
                                        }
                                    }
                                }
                            },


                   /*         {
                                value: 1800,
                                itemStyle: {
                                    color: getColor('danger-100'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('danger-300')
                                    },
                                    label: {
                                        formatter: val => `{b| 92% }`,
                                        rich: {
                                            a: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|92%}`,
                                    rich: {
                                        a: {
                                            color: getColor('danger-500'),
                                            fontWeight: 500
                                        }
                                    }
                                }
                            },*/

              /*              {
                                value: 2000,
                                itemStyle: {
                                    color: getColor('danger-200'),
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('danger-300')
                                    },
                                    label: {
                                        formatter: val => `{b| 98% }`,
                                        rich: {
                                            a: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: () => `{b|98%}`,
                                    rich: {
                                        a: {
                                            color: getColor('danger-500'),
                                            fontWeight: 500
                                        }
                                    }
                                }
                            },

*/

                           /* {
                                value: 2100,
                                itemStyle: {
                                    // color: getColor('danger-300'),
                                    color: '#38881666',
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: getColor('success-500')
                                    },
                                    label: {
                                        formatter: val => `{b| 94% }`,
                                        rich: {
                                            a: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: val => `{b| 94% }`,
                                    rich: {
                                        a: {
                                            color: getColor('white'),
                                            fontWeight: 500
                                        }
                                    }
                                }
                            },
*/
                            
                            {
                                value: 2400,
                                itemStyle: {
                                    color: '#3532A7A6',
                                    opacity: 0.9,
                                    borderRadius: [4, 0, 0, 4]
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: '#3532A7',
                                    },
                                    label: {
                                        formatter: val => `{a|${val.value}}`,
                                        rich: {
                                            a: {
                                                color: getColor('white')
                                            }
                                        }
                                    }
                                },
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: val => `{a|${val.value}}`,
                                    rich: {
                                        a: {
                                            color: getColor('white'),
                                            fontWeight: 600
                                        }
                                    }
                                }
                            }
                        ],
                        barGap: '50%'
                    },
                    grid: {
                        right: 5,
                        left: 200,
                        bottom: 0,
                        top: '5%',
                        containLabel: false
                    },
                    animation: false
                }

            },

            getNewConnections() {
                return {
                    tooltip: {
                        trigger: 'axis',
                        padding: 10,
                        backgroundColor: getColor('gray-100'),
                        borderColor: getColor('gray-300'),
                        textStyle: { color: getColor('dark') },
                        borderWidth: 1,
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'none'
                        },
                        //formatter: tooltipFormatter
                    },
                    xAxis: [
                        {
                            type: 'category',

                            data: dates,
                            show: true,
                            boundaryGap: false,
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                //formatter: value => dayjs(value).format('DD MMM, YY'),
                                formatter: value => '11 Apr, 23',
                                showMinLabel: true,
                                showMaxLabel: false,
                                color: getColor('gray-800'),
                                align: 'left',
                                interval: 5,
                                fontFamily: 'Nunito Sans',
                                fontWeight: 600,
                                fontSize: 12.8
                            }
                        },
                        {
                            type: 'category',
                            position: 'bottom',
                            show: true,
                            data: dates,
                            axisLabel: {
                                //formatter: value => dayjs(value).format('DD MMM, YY'),
                                formatter: value => '22 Apr, 23',
                                interval: 130,
                                showMaxLabel: true,
                                showMinLabel: false,
                                color: getColor('gray-800'),
                                align: 'right',
                                fontFamily: 'Nunito Sans',
                                fontWeight: 600,
                                fontSize: 12.8
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                show: false
                            },
                            boundaryGap: false
                        }
                    ],
                    yAxis: {
                        show: false,
                        type: 'value',
                        boundaryGap: false
                    },
                    series: [
                        {
                            type: 'line',
                            data: [24, 35, 49, 47, 42, 47, 38, 52, 55, 44, 38, 42],
                            lineStyle: {
                                width: 2,
                                color: getColor('primary')
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: rgbaColor(getColor('primary'), 0.2)
                                        },
                                        {
                                            offset: 1,
                                            color: rgbaColor(getColor('primary'), 0)
                                        }
                                    ]
                                }
                            },
                            showSymbol: false,
                            symbol: 'circle'
                        }
                    ],
                    grid: { left: 0, right: 0, top: 5, bottom: 20 }
                }
            },

            getNewConnections1() {
                return {
                    tooltip: {
                        trigger: 'axis',
                        padding: 10,
                        backgroundColor: getColor('gray-100'),
                        borderColor: getColor('gray-300'),
                        textStyle: { color: getColor('dark') },
                        borderWidth: 1,
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'none'
                        },
                        //formatter: tooltipFormatter
                        //formatter: value => '21 Apr, 23',
                    },
                    xAxis: [
                        {
                            type: 'category',

                            data: dates,
                            show: true,
                            boundaryGap: false,
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                //formatter: value => dayjs(value).format('DD MMM, YY'),
                                formatter: '11 Apr, 23',
                                showMinLabel: true,
                                showMaxLabel: false,
                                color: getColor('gray-800'),
                                align: 'left',
                                interval: 5,
                                fontFamily: 'Nunito Sans',
                                fontWeight: 600,
                                fontSize: 12.8
                            }
                        },
                        {
                            type: 'category',
                            position: 'bottom',
                            show: true,
                            data: dates,
                            axisLabel: {
                                //formatter: value => dayjs(value).format('DD MMM, YY'),
                                formatter: '22 Apr, 23',
                                interval: 130,
                                showMaxLabel: true,
                                showMinLabel: false,
                                color: getColor('gray-800'),
                                align: 'right',
                                fontFamily: 'Nunito Sans',
                                fontWeight: 600,
                                fontSize: 12.8
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                show: false
                            },
                            boundaryGap: false
                        }
                    ],
                    yAxis: {
                        show: false,
                        type: 'value',
                        boundaryGap: false
                    },
                    series: [
                        {
                            type: 'line',
                            data: [20, 18, 29, 37, 54, 42, 62, 67, 59, 89, 90, 102],
                            lineStyle: {
                                width: 2,
                                color: getColor('primary')
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: rgbaColor(getColor('primary'), 0.2)
                                        },
                                        {
                                            offset: 1,
                                            color: rgbaColor(getColor('primary'), 0)
                                        }
                                    ]
                                }
                            },
                            showSymbol: false,
                            symbol: 'circle'
                        }
                    ],
                    grid: { left: 0, right: 0, top: 5, bottom: 20 }
                }
            },

            getAdsAnalytics() {
                return {
                    color: [
                        getColor('gray-400'),
                        getColor('primary-100'),
                        getColor('primary-300'),
                        getColor('primary-500'),
                    ],
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: getColor('gray-soft'),
                        borderColor: getColor('gray-200'),
                        //formatter: params => tooltipFormatter(params, 'MMM DD, YYYY'),
                        axisPointer: {
                            shadowStyle: {
                                color: 'red',
                            },
                        },
                    },
                    legend: {
                        bottom: '10',
                        data: [
                            {
                                name: 'Awareness',
                                icon: 'roundRect',
                            },
                            {
                                name: 'Engagement',
                                icon: 'roundRect',
                            },
                            {
                                name: 'Sale',
                                icon: 'roundRect',
                            },
                        ],
                        itemWidth: 16,
                        itemHeight: 8,
                        itemGap: 30,
                        inactiveColor: getColor('gray-500'),
                        inactiveBorderWidth: 0,
                        textStyle: {
                            color: getColor('gray-900'),
                            fontWeight: 600,
                            fontSize: 16,
                            fontFamily: 'Nunito Sans',
                        },
                    },

                    // grid: {
                    //   left: '0%',
                    //   right: '4%',
                    //   bottom: '15%',
                    //   top: '10%',
                    //   containLabel: true,
                    //   show: true
                    // },

                    xAxis: [
                        {
                            show: true,
                            interval: 2,
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: getColor('gray-300'),
                                },
                            },
                            axisLabel: {
                                color: getColor('gray-900'),
                                formatter: dates1, //=> window.dayjs(data).format('D MMM'),
                                interval: 5,
                                align: 'left',
                                margin: 20,
                                fontSize: 12.8,
                            },
                            axisTick: {
                                show: true,
                                length: 15,
                                // alignWithLabel: true
                            },
                            splitLine: {
                                interval: 0,
                                show: true,
                                lineStyle: {
                                    color: getColor('gray-300'),
                                    type: 'dashed',
                                },
                            },
                            type: 'category',
                            boundaryGap: false,
                            data: dates1,
                        },
                        {
                            show: true,
                            interval: 2,
                            axisLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                interval: 1,
                                show: true,
                                lineStyle: {
                                    color: getColor('gray-300'),
                                    type: 'solid',
                                },
                            },
                            boundaryGap: false,
                            data: dates1,
                        },
                    ],
                    yAxis: {
                        show: true,
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: getColor('gray-300'),
                            },
                        },
                        axisLabel: {
                            color: getColor('gray-900'),
                            margin: 20,
                            fontSize: 12.8,
                            interval: 0,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: getColor('gray-300'),
                                type: 'solid',
                            },
                        },
                        axisTick: {
                            show: true,
                            length: 15,
                            alignWithLabel: true,
                            lineStyle: {
                                color: getColor('gray-300'),
                            },
                        },
                        // data: ['0', '10', '20']
                    },
                    series: [
                        {
                            name: 'Estimated',
                            type: 'line',
                            symbol: 'none',
                            data: [20, 17.5, 15, 15, 15, 12.5, 10, 7.5, 5, 2.5, 2.5, 2.5, 0],
                            lineStyle: {
                                width: 0,
                            },
                            areaStyle: {
                                color: getColor('primary-300'),
                                opacity: 0.075,
                            },
                            tooltip: {
                                show: false,
                            },
                        },
                        {
                            name: 'Awareness',
                            type: 'line',
                            symbolSize: 6,
                            data: [3, 1, 2, 4, 3, 1, 6],
                        },
                        {
                            name: 'Engagement',
                            type: 'line',
                            symbolSize: 6,
                            data: [6, 5, 4, 6, 5, 5, 7],
                        },
                        {
                            name: 'Sale',
                            type: 'line',
                            symbolSize: 6,
                            data: [11, 12, 11, 9, 11, 11, 10],
                        },
                        {
                            name: 'Total',
                            type: 'line',
                            symbolSize: 6,
                            data: [20, 19, 17, 19, 19, 21, 23],
                            lineStyle: {
                                type: 'dashed',
                            },
                        },
                    ],
                    grid: {
                        right: 5,
                        left: 0,
                        bottom: '15%',
                        top: 20,
                        containLabel: true,
                    },
                }
            }
        }                       
    };

    const getColor = (name, dom = document.documentElement) => {
        return getComputedStyle(dom).getPropertyValue(`--phoenix-${name}`).trim();
    };

    console.log();

    const data1 = [24, 14, 30, 24, 32, 32, 18, 12, 32];
    const data2 = [36, 28, 36, 39, 54, 38, 22, 34, 52];

    const rgbaColor = (color = '#fff', alpha = 0.5) =>
        `rgba(${hexToRgb(color)}, ${alpha})`;

    const hexToRgb = hexValue => {
        let hex;
        hexValue.indexOf('#') === 0
            ? (hex = hexValue.substring(1))
            : (hex = hexValue);
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
            hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
        );
        return result
            ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16),
            ]
            : null;
    };
</script>

<style scoped>
</style>