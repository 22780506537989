<template>
    <div>
        <!-- Your authentication form and logic go here -->
        <!-- ===============================================-->
        <!--    Main Content-->
        <!-- ===============================================-->
                <div class="container-fluid">
                    <div class="bg-holder bg-auth-card-overlay">
                    </div>
                    <!--/.bg-holder-->
                    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
                        <div class="col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4"> <!-- SD set card sizes-->
                            <div class="card border border-300 auth-card">
                                <div class="card-body"> <!-- SD delete pe-md-0 -->
                                    <div class="row align-items-center gx-0 gy-7 pt-2 pb-2">
                                        <!-- SD Add -->
                                        <div class="col mx-auto">
                                            <!-- SD Erase code before -->
                                            <div class="auth-form-box">
                                                <div class="text-center mb-7">
                                                    <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                                                        <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                                                            <img src="../assets/img/logo-cleverup-logo.png" alt="clever-up" width="80" />
                                                        </div>
                                                    </a>
                                                    <h3 class="text-1000">Sign In</h3>
                                                    <p class="text-700">Get access to your account</p>
                                                </div>
                                                <form @submit.prevent="login">
                                                    <div class="mb-3 text-start">
                                                        <label class="form-label" for="email">Email address</label>
                                                        <div class="form-icon-container">
                                                            <input class="form-control form-icon-input" id="email" type="email" placeholder="Email" v-model="email" /><span class="fas fa-user text-900 fs--1 form-icon"><font-awesome-icon :icon="['fas', 'user']" /></span>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3 text-start">
                                                        <label class="form-label" for="password">Password</label>
                                                        <div class="form-icon-container">
                                                            <input class="form-control form-icon-input" id="password" type="password" placeholder="Password" v-model="password" /><span class="fas fa-key text-900 fs--1 form-icon"><font-awesome-icon :icon="['fas', 'key']" /></span>
                                                        </div>
                                                    </div>
                                                    <div class="row flex-between-center mb-7">
                                                        <div class="col-auto">
                                                            <div class="form-check mb-0">
                                                                <input class="form-check-input" id="basic-checkbox" type="checkbox" checked="checked" />
                                                                <label class="form-check-label mb-0" for="basic-checkbox">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto"><a class="fs--1 fw-semi-bold" href="/password-recovery">Forgot Password?</a></div>
                                                    </div>
                                                    <div class="alert alert-soft-danger" role="alert" v-if="error">{{ error }}</div>

                                                    <button class="btn btn-primary w-100 mb-3" type="submit" :disabled="loading" >
                                                        <span v-if="!loading">Sign In </span>
                                                        <font-awesome-icon class="awesome-spiner" :icon="['fas', 'gear']" spin size="lg" v-if="loading"/> 
                                                    </button>
                                                    <div class="text-center"><a class="fs--1 fw-bold" href="/signup">Create an account</a></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <!-- ===============================================-->
        <!--    End of Main Content-->
        <!-- ===============================================-->
    </div>
</template>

<script>
    import authService from '@/services/authService';
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    export default {
        name: 'Auth',
        data() {
            return {
                email: '',
                password: '',
                error: null,
                loading: false,
            };
        },
        setup() {
            const store = useStore();
            const router = useRouter();
            /*const isLoggedIn = computed(() => store.getters.isLoggedIn);*/
            const getIntendedRoute = computed(() => store.getters.getIntendedRoute);

         /*   console.log("isLoggedIn: ", isLoggedIn.value);*/
            console.log("getIntendedRoute: ", getIntendedRoute.value);

            return {
              /*  isLoggedIn,*/
                getIntendedRoute,
                router,
                store // return store here
            };
        },
        methods: {
            async login() {
                try {
                    this.loading = true;
                    await authService.login(this.email, this.password);
                    this.loading = false;
                    console.log("getIntendedRoute login: ", this.getIntendedRoute);
                    if (this.getIntendedRoute) {
                        this.router.push(this.getIntendedRoute);
                        this.store.commit('clearIntendedRoute'); // use this.store here
                    } else {
                        this.router.push({ name: 'Dashboard' });
                    }
                } catch (error) {
                    this.loading = false;
                    this.error = error.message;
                }
            },
        },
    };
</script>