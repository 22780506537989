<!-- src/components/SignUp.vue -->
<template>
    <div>
        <!-- Add your sign-up form here -->
        <!-- ===============================================-->
        <!--    Main Content-->
        <!-- ===============================================-->
 
                <div class="container-fluid">
                    <div class="bg-holder bg-auth-card-overlay">
                    </div>
                    <!--/.bg-holder-->
                    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
                        <div class="col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
                            <div class="card border border-300 auth-card">
                                <div class="card-body">
                                    <div class="row align-items-center gx-0 gy-7 pt-2 pb-2">
                                        <div class="col mx-auto">
                                            <div class="auth-form-box">
                                                <div class="text-center mb-7">
                                                    <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                                                        <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                                                            <img src="../assets/img/logo-cleverup-logo.png" alt="clever-up" width="80" />
                                                        </div>
                                                    </a>
                                                    <h3 class="text-1000">Sign Up</h3>
                                                    <p class="text-700">Create your account today</p>
                                                </div>
                                                <form>
                                                    <div class="mb-3 text-start">
                                                        <label class="form-label" for="name">Name</label>
                                                        <input class="form-control" id="name" type="text" placeholder="Name" />
                                                    </div>
                                                    <div class="mb-3 text-start">
                                                        <label class="form-label" for="email">Email address</label>
                                                        <input class="form-control" id="email" type="email" placeholder="Email" />
                                                    </div>
                                                    <div class="row g-3 mb-3">
                                                        <div class="col-xl-6">
                                                            <label class="form-label" for="password">Password</label>
                                                            <input class="form-control form-icon-input" id="password" type="password" placeholder="Password" />
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <label class="form-label" for="confirmPassword">Confirm Password</label>
                                                            <input class="form-control form-icon-input" id="confirmPassword" type="password" placeholder="Confirm Password" />
                                                        </div>
                                                    </div>
                                                    <div class="form-check mb-3">
                                                        <input class="form-check-input" id="termsService" type="checkbox" />
                                                        <label class="form-label fs--1 text-none" for="termsService">I accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label>
                                                    </div>
                                                    <button class="btn btn-primary w-100 mb-3" :disabled="loading">
                                                        <span v-if="!loading"> Sign up </span>
                                                        <font-awesome-icon class="awesome-spiner" :icon="['fas', 'gear']" spin size="lg" v-if="loading" />
                                                    </button>
                                                    <div class="text-center"><a class="fs--1 fw-bold" href="/auth">Sign in to an existing account</a></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <!-- ===============================================-->
        <!--    End of Main Content-->
        <!-- ===============================================-->
    </div>
</template>

<script>
export default {
        name: 'SignUp',
        data() {
            return {
                email: '',
                password: '',
                error: null,
                loading: false,
            };
        },
        methods: {
            async register() {
                try {
                    this.loading = true;
                    // await authService.login(this.email, this.password);
                    this.loading = false;
                    // this.$router.push('/dashboard'); // Redirect the user to the dashboard after successful login
                } catch (error) {
                    this.loading = false;
                    this.error = error.message;
                }
            },
        },
};
</script>