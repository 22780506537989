<template>
    <div class="mt-n5">
       <!-- <h3>Leads</h3>-->
        <div class="d-xl-flex justify-content-between">
            <div class="mb-3">
                <button class="btn btn-primary me-4" type="button" data-bs-toggle="modal" data-bs-target="#addLeadModal" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="me-2"><font-awesome-icon :icon="['fas', 'fa-plus']" /></span>Add Lead</button>
                <button class="btn btn-link text-900 px-0 me-4"><span class="fa-solid fa-file-export fs--1 me-2"><font-awesome-icon :icon="['fas', 'fa-file-export']" /></span>Export</button>
                <button class="btn btn-link text-900 px-0 me-4" @click="importData"><span class="fa-solid fa-file-import fs--1 me-2"><font-awesome-icon :icon="['fas', 'file-import']" /></span>Import</button>
    <!--            <button class="btn btn-link text-900 px-0" @click="deleteAllLeads"><span class="fa-solid fa-file-import fs--1 me-2"><font-awesome-icon :icon="['fas', 'trash-can']" /></span>Delete all</button>-->
                <input type="file" @change="handleFileUpload" style="display: none;" ref="fileInput" accept=".json" />
            </div>
            <div class="d-flex mb-4">
                <div class="search-box">
                    <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                        <input class="form-control search-input search" type="search" placeholder="Search by name" aria-label="Search" v-model="searchTerm" @keydown.enter.prevent/>
                        <span class="fas fa-search search-box-icon"><font-awesome-icon :icon="['fas', 'fa-search']" /></span>

                    </form>
                </div>
                <select class="form-select w-auto mx-2" id="select-deals">
                    <option>Test campaign #1</option>
                    <option>Test campaign #2</option>
                    <option>Test campaign #3</option>
                </select>
                <button class="btn px-3 btn-phoenix-secondary" type="button" data-bs-toggle="modal" data-bs-target="#reportsFilterModal" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fa-solid fa-filter text-primary" data-fa-transform="down-3"><font-awesome-icon :icon="['fas', 'fa-filter']" /></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import importService from '../services/importService';
import axios from 'axios';

import _ from 'lodash';
import emitter from '@/eventBus';

const API_BASE_URL = 'https://api.cleverup.io';

export default {
        name: 'KanbanHeader',
        data() {
            return {
                searchTerm: '',
            };
        },
        watch: {
            searchTerm: _.debounce(function (newSearchTerm) {
                emitter.emit('search', newSearchTerm);
            }, 700),
        },
        methods: {
            handleFileUpload(evt) {
                const file = evt.target.files[0];
                const reader = new FileReader();
               
                reader.onload = async (e) => {
                    try {
                        const jsonData = JSON.parse(e.target.result);
                        await importService.importLeads(jsonData).then(({ results, message }) => {
                            // Process results if needed
                            console.log(results);

                            // Display the success message
                            console.log(message); // You can replace this with any UI logic to display the message
                        })
                        .catch(error => {
                            // Handle error if necessary
                            console.error(error);
                        });;
                    } catch (error) {
                        console.error('Error parsing or sending data:', error);
                    } finally {
                        // Reset the value of the file input element
                        evt.target.value = '';
                    }
                };

                reader.onerror = (e) => {
                    console.error('Error reading file:', e.target.error);
                };

                reader.readAsText(file);
            },
            importData() {
                this.$refs.fileInput.click();
            },
            // Delete all leads with API call /api/leads/delete
            deleteAllLeads() {
                // Assuming you're storing your token in local storage or similar
                const token = localStorage.getItem('accessToken');

                axios.post(`${API_BASE_URL}/api/leads/delete`, {
                    // You can send any data you need for the request here.
                    // If you don't need to send any data, you can leave this object empty.
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        
                    })
                    .catch(error => {
                        console.error('There was an error: ', error);
                    });
            },


        },
    }
</script>

<style scoped>
</style>