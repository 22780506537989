import axios from 'axios';
import store from '@/store/';

const API_BASE_URL = 'https://api.cleverup.io';

export default {
    async login(email, password) {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
                email,
                password,
            });

            if (response.data && response.data.access_token) {
                store.commit('setAccessToken', response.data.access_token);
                localStorage.setItem('accessToken', response.data.access_token);
                return response.data;
            } else {
                throw new Error('Invalid login credentials');
            }
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    },

    logout() {
        store.commit('clearAccessToken');
        localStorage.removeItem('accessToken');
    },

    isAuthenticated() {
        return !!store.getters.getAccessToken;
    },

    getAccessToken() {
        return store.getters.getAccessToken;
    },
};