import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import store from './store';
import router from './router';
import authService from '@/services/authService';

import 'bootstrap';
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';
import 'echarts/lib/chart/funnel';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, ToolboxComponent, LegendComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';

import emitter from '@/eventBus';

import './vendors/popper/popper.min.js';
//import './vendors/dayjs/dayjs.min.js';

// Register the required components
use([
    CanvasRenderer,
    BarChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    ToolboxComponent,
    LegendComponent,
]);

// Optional: Import any global CSS or SCSS files
import './assets/css/theme.css';
import './assets/css/user.css';
import "./vendors/simplebar/simplebar.min.css";

// Import Font Awesome packages
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add the free solid icons to the library
library.add(fas, far, fab);

axios.defaults.baseURL = 'https://api.cleverup.io';

axios.interceptors.response.use(
    function (response) {
        // If the request succeeds, we don't have to do anything and just return the response
        return response;
    },
    function (error) {
        // If the server responds with a 401 Unauthorized or 403 Forbidden response
        if (error.response.status === 401 || error.response.status === 403) {
            // Perform any logic to clear user data
            authService.logout();
            // Redirect to the login page
            router.push('/auth');
        }

        return Promise.reject(error);
    }
);

const app = createApp(App);

app.config.globalProperties.$emitter = emitter;

app.component('font-awesome-icon', FontAwesomeIcon); // Register the FontAwesomeIcon component globally
app.component('v-chart', ECharts); // Echart

app.use(store);
app.use(router);

app.mount('#app');
