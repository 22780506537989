<template>
    <div class="modal fade" id="addLeadModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addLeadModal" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content bg-100 p-6">
                <div class="modal-header border-0 p-0 mb-2">
                    <h3 class="mb-0">Lead Informations</h3>
                    <button class="btn btn-sm btn-phoenix-secondary" data-bs-dismiss="modal" aria-label="Close"><svg class="svg-inline--fa fa-xmark text-danger" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                </div>
                <div class="modal-body px-0">
                    <div class="row g-4">
                        <div class="col-lg-6">
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">Lead Owner</label>
                                <select class="form-select">
                                    <option>Select</option>
                                    <option>Ally Aagaard</option>
                                    <option>Aida Moen</option>
                                    <option>Niko Koss</option>
                                    <option>Alec Haag</option>
                                    <option>Lonnie Kub</option>
                                    <option>Ola Smith</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">Lead Name</label>
                                <input class="form-control" type="text" placeholder="Enter lead name" />
                            </div>
                            <div class="mb-4">
                                <div class="row g-3">
                                    <div class="col-sm-6 col-lg-12 col-xl-6">
                                        <label class="text-1000 fw-bold mb-2">Lead Amount</label>
                                        <div class="row g-2">
                                            <div class="col">
                                                <input class="form-control" type="number" placeholder="$ Enter amount" />
                                            </div>
                                            <div class="col-auto">
                                                <select class="form-select">
                                                    <option>USD</option>
                                                    <option>GBP</option>
                                                    <option>EUR</option>
                                                    <option>JPY</option>
                                                    <option>CAD</option>
                                                    <option>AUD</option>
                                                    <option>CNY</option>
                                                    <option>CHF</option>
                                                    <option>ZAR</option>
                                                    <option>BRL</option>
                                                    <option>RUB</option>
                                                    <option>INR</option>
                                                    <option>MXN</option>
                                                    <option>NZD</option>
                                                    <option>SGD</option>
                                                    <option>HKD</option>
                                                    <option>KRW</option>
                                                    <option>SEK</option>
                                                    <option>NOK</option>
                                                    <option>TRY</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-lg-12 col-xl-6">
                                        <label class="text-1000 fw-bold mb-2">Lead Code</label>
                                        <input class="form-control" type="text" placeholder="Enter leads code" />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">
                                    Lead Type
                                    <button class="btn btn-link p-0 ms-3"><span class="fa-solid fa-plus me-1"></span><span>Add new</span></button>
                                </label>
                                <select class="form-select">
                                    <option>Select</option>
                                    <option>Buy One Get One Free</option>
                                    <option>Clearance sale</option>
                                    <option>Bundle leads</option>
                                    <option>Free shipping</option>
                                    <option>Loyalty programs</option>
                                    <option>Limited-time offers</option>
                                    <option>Refer-a-friend discounts</option>
                                    <option>Seasonal sales</option>
                                    <option>Membership discounts</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">
                                    Category
                                    <button class="btn btn-link p-0 ms-3"><span class="fa-solid fa-plus me-1"></span><span>Add new</span></button>
                                </label>
                                <select class="form-select">
                                    <option>Select</option>
                                    <option>Financial</option>
                                    <option>Marketplace</option>
                                    <option>Travel</option>
                                    <option>E-commerce</option>
                                    <option>Cloud Computing</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <div class="row g-3">
                                    <div class="col-sm-6 col-lg-12 col-xl-6">
                                        <label class="text-1000 fw-bold mb-2">Probability (%)</label>
                                        <input class="form-control" type="text" placeholder="Enter value" />
                                    </div>
                                    <div class="col-sm-6 col-lg-12 col-xl-6">
                                        <label class="text-1000 fw-bold mb-2">Expected Revenue</label>
                                        <div class="row g-2">
                                            <div class="col">
                                                <input class="form-control" type="number" placeholder="$ Enter amount" />
                                            </div>
                                            <div class="col-auto">
                                                <select class="form-select">
                                                    <option>USD</option>
                                                    <option>GBP</option>
                                                    <option>EUR</option>
                                                    <option>JPY</option>
                                                    <option>CAD</option>
                                                    <option>AUD</option>
                                                    <option>CNY</option>
                                                    <option>CHF</option>
                                                    <option>ZAR</option>
                                                    <option>BRL</option>
                                                    <option>RUB</option>
                                                    <option>INR</option>
                                                    <option>MXN</option>
                                                    <option>NZD</option>
                                                    <option>SGD</option>
                                                    <option>HKD</option>
                                                    <option>KRW</option>
                                                    <option>SEK</option>
                                                    <option>NOK</option>
                                                    <option>TRY</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row g-3">
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Stage</label>
                                        <select class="form-select">
                                            <option>Select</option>
                                            <option>New</option>
                                            <option>In Progress</option>
                                            <option>Pending</option>
                                            <option>Canceled</option>
                                            <option>Completed</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Priority</label>
                                        <select class="form-select">
                                            <option>Urgent</option>
                                            <option>High</option>
                                            <option>Medium</option>
                                            <option>Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">Contact Name</label>
                                <input class="form-control" type="text" placeholder="Enter contact name" />
                            </div>
                            <div class="mb-4">
                                <div class="row g-3">
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Phone Number</label>
                                        <input class="form-control" type="text" placeholder="Enter phone number" />
                                    </div>
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Email Address</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">
                                    Lead Source
                                    <button class="btn btn-link p-0 ms-3"><span class="fa-solid fa-plus me-1"></span><span>Add new</span></button>
                                </label>
                                <select class="form-select">
                                    <option>Select</option>
                                    <option>Referrals</option>
                                    <option>Former Clients</option>
                                    <option>Competitors</option>
                                    <option>Business & sales</option>
                                    <option>Google resources</option>
                                    <option>Linkedin</option>
                                    <option>Marketing</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">
                                    Campaign Source
                                    <button class="btn btn-link p-0 ms-3"><span class="fa-solid fa-plus me-1"></span><span>Add new</span></button>
                                </label>
                                <select class="form-select">
                                    <option>Select</option>
                                    <option>Online Campaign</option>
                                    <option>Offline Campaign</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label class="text-1000 fw-bold mb-2">Time Zone</label>
                                <select class="form-select">
                                    <option>GMT-12:00 Etc/GMT-12</option>
                                    <option>GMT-11:00 Etc/GMT-11</option>
                                    <option>GMT-11:00 Pacific/Midway</option>
                                    <option>GMT-10:00 America/Adak</option>
                                    <option>GMT-09:00 America/Anchorage</option>
                                    <option>GMT-09:00 Pacific/Gambier</option>
                                    <option>GMT-08:00 America/Dawson_Creek</option>
                                    <option>GMT-08:00 America/Ensenada</option>
                                    <option>GMT-08:00 America/Los_Angeles</option>
                                    <option>GMT-07:00 America/Chihuahua</option>
                                    <option>GMT-07:00 America/Denver</option>
                                    <option>GMT-06:00 America/Belize</option>
                                    <option>GMT-06:00 America/Cancun</option>
                                    <option>GMT-06:00 America/Chicago</option>
                                    <option>GMT-06:00 Chile/EasterIsland</option>
                                    <option>GMT-05:00 America/Bogota</option>
                                    <option>GMT-05:00 America/Havana</option>
                                    <option>GMT-05:00 America/New_York</option>
                                    <option>GMT-04:30 America/Caracas</option>
                                    <option>GMT-04:00 America/Campo_Grande</option>
                                    <option>GMT-04:00 America/Glace_Bay</option>
                                    <option>GMT-04:00 America/Goose_Bay</option>
                                    <option>GMT-04:00 America/Santiago</option>
                                    <option>GMT-04:00 America/La_Paz</option>
                                    <option>GMT-03:00 America/Argentina/Buenos_Aires</option>
                                    <option>GMT-03:00 America/Montevideo</option>
                                    <option>GMT-03:00 America/Araguaina</option>
                                    <option>GMT-03:00 America/Godthab</option>
                                    <option>GMT-03:00 America/Miquelon</option>
                                    <option>GMT-03:00 America/Sao_Paulo</option>
                                    <option>GMT-03:30 America/St_Johns</option>
                                    <option>GMT-02:00 America/Noronha</option>
                                    <option>GMT-01:00 Atlantic/Cape_Verde</option>
                                    <option>GMT Europe/Belfast</option>
                                    <option>GMT Africa/Abidjan</option>
                                    <option>GMT Europe/Dublin</option>
                                    <option>GMT Europe/Lisbon</option>
                                    <option>GMT Europe/London</option>
                                    <option>UTC UTC</option>
                                    <option>GMT+01:00 Africa/Algiers</option>
                                    <option>GMT+01:00 Africa/Windhoek</option>
                                    <option>GMT+01:00 Atlantic/Azores</option>
                                    <option>GMT+01:00 Atlantic/Stanley</option>
                                    <option>GMT+01:00 Europe/Amsterdam</option>
                                    <option>GMT+01:00 Europe/Belgrade</option>
                                    <option>GMT+01:00 Europe/Brussels</option>
                                    <option>GMT+02:00 Africa/Cairo</option>
                                    <option>GMT+02:00 Africa/Blantyre</option>
                                    <option>GMT+02:00 Asia/Beirut</option>
                                    <option>GMT+02:00 Asia/Damascus</option>
                                    <option>GMT+02:00 Asia/Gaza</option>
                                    <option>GMT+02:00 Asia/Jerusalem</option>
                                    <option>GMT+03:00 Africa/Addis_Ababa</option>
                                    <option>GMT+03:00 Asia/Riyadh89</option>
                                    <option>GMT+03:00 Europe/Minsk</option>
                                    <option>GMT+03:30 Asia/Tehran</option>
                                    <option>GMT+04:00 Asia/Dubai</option>
                                    <option>GMT+04:00 Asia/Yerevan</option>
                                    <option>GMT+04:00 Europe/Moscow</option>
                                    <option>GMT+04:30 Asia/Kabul</option>
                                    <option>GMT+05:00 Asia/Tashkent</option>
                                    <option>GMT+05:30 Asia/Kolkata</option>
                                    <option>GMT+05:45 Asia/Katmandu</option>
                                    <option>GMT+06:00 Asia/Dhaka</option>
                                    <option>GMT+06:00 Asia/Yekaterinburg</option>
                                    <option>GMT+06:30 Asia/Rangoon</option>
                                    <option>GMT+07:00 Asia/Bangkok</option>
                                    <option>GMT+07:00 Asia/Novosibirsk</option>
                                    <option>GMT+08:00 Etc/GMT+8</option>
                                    <option>GMT+08:00 Asia/Hong_Kong</option>
                                    <option>GMT+08:00 Asia/Krasnoyarsk</option>
                                    <option>GMT+08:00 Australia/Perth</option>
                                    <option>GMT+08:45 Australia/Eucla</option>
                                    <option>GMT+09:00 Asia/Irkutsk</option>
                                    <option>GMT+09:00 Asia/Seoul</option>
                                    <option>GMT+09:00 Asia/Tokyo</option>
                                    <option>GMT+09:30 Australia/Adelaide</option>
                                    <option>GMT+09:30 Australia/Darwin</option>
                                    <option>GMT+09:30 Pacific/Marquesas</option>
                                    <option>GMT+10:00 Etc/GMT+10</option>
                                    <option>GMT+10:00 Australia/Brisbane</option>
                                    <option>GMT+10:00 Australia/Hobart</option>
                                    <option>GMT+10:00 Asia/Yakutsk</option>
                                    <option>GMT+10:30 Australia/Lord_Howe</option>
                                    <option>GMT+11:00 Asia/Vladivostok</option>
                                    <option>GMT+11:30 Pacific/Norfolk</option>
                                    <option>GMT+12:00 Etc/GMT+12</option>
                                    <option>GMT+12:00 Asia/Anadyr</option>
                                    <option>GMT+12:00 Asia/Magadan</option>
                                    <option>GMT+12:00 Pacific/Auckland</option>
                                    <option>GMT+12:45 Pacific/Chatham</option>
                                    <option>GMT+13:00 Pacific/Tongatapu</option>
                                    <option>GMT+14:00 Pacific/Kiritimati</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <div class="row g-3">
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Create Date</label>
                                        <input class="form-control datetimepicker" type="text" placeholder="dd / mm / yyyy" data-options='{"disableMobile":true}' />
                                    </div>
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Start Time</label>
                                        <input class="form-control datetimepicker" type="text" placeholder="H:i" data-options='{"enableTime":true,"noCalendar":true,"dateFormat":"H:i","disableMobile":true}' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row g-3">
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Closing Date</label>
                                        <input class="form-control datetimepicker" type="text" placeholder="dd / mm / yyyy" data-options='{"disableMobile":true}' />
                                    </div>
                                    <div class="col-6">
                                        <label class="text-1000 fw-bold mb-2">Closing Time</label>
                                        <input class="form-control datetimepicker" type="text" placeholder="H:i" data-options='{"enableTime":true,"noCalendar":true,"dateFormat":"H:i","disableMobile":true}' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 pt-6 px-0 pb-0">
                    <button class="btn btn-link text-danger px-3 my-0" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button class="btn btn-primary my-0">Create Lead</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LeadAddModal',
    };
</script>

<style scoped>
</style>