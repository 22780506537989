<template>
    <header>
    </header>
</template>

<script>
    export default {
        name: 'Header',
    };
</script>

<style scoped>
    /* Add your header styles here */
</style>