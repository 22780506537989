<!-- LeadConversionChart.vue -->
<template>
    <div>
        <v-chart :option="chartOptions" :auto-resize="true" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                chartOptions: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: 'Sales',
                            type: 'bar',
                            data: [120, 200, 150, 80, 70, 110, 130],
                        },
                    ],
                },
            };
        },
    };
</script>