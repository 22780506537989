<template>
    <div>
        <!-- ===============================================-->
        <!--    Main Content-->
        <!-- ===============================================-->

                <div class="container-fluid">
                    <div class="bg-holder bg-auth-card-overlay">
                    </div>
                    <!--/.bg-holder-->

                    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
                        <div class="col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
                            <div class="card border border-300 auth-card">
                                <div class="card-body pe-md-0">
                                    <div class="row align-items-center gx-0 gy-7 pt-2 pb-2">                              
                                        <div class="col mx-auto">
                                            <div class="auth-form-box">
                                                <div class="text-center mb-7">
                                                    <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                                                        <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                                                            <img src="../assets/img/logo-cleverup-logo.png" alt="clever-up" width="80" />
                                                        </div>
                                                    </a>
                                                    <h4 class="text-1000">Reset new password</h4>
                                                    <p class="text-700">Type your new password</p>
                                                </div>
                                                <form class="mt-5">
                                                    <input class="form-control mb-2" id="password" type="password" placeholder="Type new password" />
                                                    <input class="form-control mb-4" id="confirmPassword" type="password" placeholder="Cofirm new password" />
                                                    <button class="btn btn-primary w-100" type="submit">Set Password</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <!-- ===============================================-->
        <!--    End of Main Content-->
        <!-- ===============================================-->

    </div>
</template>

<script>
    export default {
        name: 'ResetPassword',
    };
</script>