<template>
    <div id="app">
        <Header v-if="!isAuthPage" />
        <VerticalNavbar v-if="!isAuthPage" />
        <TopNavbar v-if="!isAuthPage" />
        <SearchBoxModal v-if="!isAuthPage" />

        <router-view></router-view>

        <Footer v-if="!isAuthPage" />
    </div>
</template>

<script>
    import Header from './components/Header.vue';
    import Footer from './components/Footer.vue';
    import VerticalNavbar from './components/VerticalNavbar.vue';
    import TopNavbar from './components/TopNavbar.vue';
    import SearchBoxModal from './components/SearchBoxModal.vue';

    export default {
        name: 'App',
        components: {
            Header,
            Footer,
            VerticalNavbar,
            TopNavbar,
            SearchBoxModal
        },
        computed: {
            isAuthPage() {
                return ['Auth', 'SignUp', 'PasswordRecovery', 'ResetPassword'].includes(this.$route.name);
            },
        },
    };
</script>

<style scoped>
    
</style>