<template>
    <div class="content">
        <div class="mx-lg-n4 pt-3">
            <kanban-header></kanban-header>
            <kanban @mounted="onKanbanMounted"></kanban>
        </div>
    </div>
    <lead-add-modal></lead-add-modal>
</template>

<script>
    import Kanban from './Kanban.vue';
    import KanbanHeader from './KanbanHeader.vue';
    import LeadAddModal from './LeadAddModal.vue';
    import Sortable from "sortablejs";
    import axios from 'axios';
    import { mapMutations, mapGetters } from 'vuex';

    const API_BASE_URL = 'https://api.cleverup.io';

    import emitter from '@/eventBus';

    export default {
        name: 'Leads',
        components: {
            Kanban,
            KanbanHeader,
            LeadAddModal
        },
        computed: {
        ...mapGetters(['getLeadsStageCounter']), // map the getter to a computed property
            currentCounter: {
                get() {
                    return this.getLeadsStageCounter(this.title);
                },
                set(value) {
                    this.setLeadsStageCounter({ stage: this.title, page: value });
                }
            }
        },
        methods: {
            ...mapMutations(['setLeadsStageCounter']), // map the mutation to a method
            updateDatabase(evt) {
                const itemID = evt.item.id;

                const stageName = evt.to.dataset.stageName;
                const oldStageName = evt.from.dataset.stageName;

                const stageCounter = this.getLeadsStageCounter(stageName);
                const oldStageCounter = this.getLeadsStageCounter(oldStageName);

                return new Promise((resolve, reject) => {
                    const token = localStorage.getItem('accessToken');

                    axios.post(`${API_BASE_URL}/api/kanban/set`, {
                        itemID: itemID,
                        stageName: stageName
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(response => {
                        if (response.data.error) {
                        }
                        else {

                            if (stageName === oldStageName) {
                                return;
                            }
                            else {
                                this.setLeadsStageCounter({ stage: stageName, count: stageCounter + 1 });
                                this.setLeadsStageCounter({ stage: oldStageName, count: oldStageCounter - 1 });

                                emitter.emit('card-moved', { new: stageName, old: oldStageName, id: itemID});
                            }
                        }
                    })
                        .catch(error => {
                            console.error('There was an error setting the kanban data:', error);
                        });
                    resolve();
                });
            },
            onKanbanMounted(kanbanRoot) {
                const sortableEls = kanbanRoot.querySelectorAll('[data-sortable]');
                const sortableDropZones = kanbanRoot.querySelectorAll('[data-dropzone]');

                kanbanRoot.addEventListener('click', e => {
                    if (e.target.hasAttribute('data-kanban-collapse')) {
                        e.target.closest('.kanban-column').classList.toggle('collapsed');
                    }
                });

                const self = this;  // Capture Vue instance context

                sortableDropZones.forEach(el => {
                    Sortable.create(el, {
                        group: {
                            name: 'shared',
                        },
                        ghostClass: 'sortable-ghost-dropzone', 
                        onAdd: function (evt) {
                            evt.item.parentNode.removeChild(evt.item);
                        }
                    });
                    }
                );

                const dropZoneJunk = document.getElementById('drop-zone-junk');
                const dropZoneSuccess = document.getElementById('drop-zone-success');

                sortableEls.forEach(el => {
                    Sortable.create(el, {
                        animation: 150,
                        group: {
                            name: 'shared',
                        },
                        delay: 100,
                        delayOnTouchOnly: true,
                        forceFallback: true,
                        onStart() {
                            document.body.classList.add('sortable-dragging');
                            emitter.emit('card-move-started');
                        },
                        onEnd: function (/**Event*/evt) {  
                            self.updateDatabase(evt);           
                            document.body.classList.remove('sortable-dragging');
                            emitter.emit('card-move-ended');

                            dropZoneJunk.classList.remove('expanded-dropzone');
                            dropZoneSuccess.classList.remove('expanded-dropzone');
                            dropZoneJunk.classList.remove('drop-zone-border-right');
                            dropZoneSuccess.classList.remove('drop-zone-border-left');
                        },
                        onMove: function (/**Event*/evt) {
                            if (evt.to.classList.contains('drop-zone')) {
                                emitter.emit('card-move-hover-dropzone');
                                if (evt.to.classList.contains('success')) {
                                    dropZoneJunk.classList.remove('expanded-dropzone');
                                    evt.to.classList.add('expanded-dropzone');
                                    evt.to.classList.add('drop-zone-border-left');
                                    dropZoneJunk.classList.remove('drop-zone-border-right');
                                    emitter.emit('card-move-hover-dropzone-success');
                                }
                                if (evt.to.classList.contains('junk')) {
                                    dropZoneSuccess.classList.remove('expanded-dropzone');
                                    evt.to.classList.add('expanded-dropzone');
                                    evt.to.classList.add('drop-zone-border-right');
                                    dropZoneSuccess.classList.remove('drop-zone-border-left');
                                    emitter.emit('card-move-hover-dropzone-junk');
                                }
                            }
                            else {
                                dropZoneJunk.classList.remove('expanded-dropzone');
                                dropZoneJunk.classList.remove('drop-zone-border-right');
                                dropZoneSuccess.classList.remove('expanded-dropzone');
                                dropZoneSuccess.classList.remove('drop-zone-border-left');
                            }
                        }
                    });
                });
            },
        },
    };
</script>

<style scoped>
</style>