<template>
    <div class="kanban-card" :id="card.id">
        <div :class="'card '+card.probability" style="margin-bottom: 13px;">
            <div class="card-body">
                <a class="dropdown-indicator-icon position-absolute text-700" :href="'#collapseWidthDeals-' + card.id" role="button" data-bs-toggle="collapse" aria-expanded="false" :aria-controls="'collapseWidthDeals-' + card.id"><span class="fa-solid fa-angle-down"><font-awesome-icon :icon="['fas', 'fa-angle-down']" /></span></a>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <div class="d-flex card-head align-items-center">
                        <span class="me-2" data-feather="clock" style="stroke-width:2;"><font-awesome-icon :icon="['far', 'fa-clock']" /></span>
                        <p class="mb-0 fs--1 fw-semi-bold text-700 date">{{ cardDate }}<span class="text-500" style="margin-left: 5px;">{{ cardTime }}</span></p>
                    </div>
                </div>
                <div class="deals-items-head d-flex align-items-center mb-2">
                    <a class="text-container text-primary fw-bold line-clamp-1 me-1 mb-0 fs-1 text-decoration-none" href="../../apps/crm/deal-details.html">{{card.full_name}}</a><a :href="card.linkedin" target="_blank"> <span class="fab fa-linkedin-in text-500 text-primary"><font-awesome-icon :icon="['fab', 'fa-linkedin']" /></span></a>
                    <p class="ms-auto fs--1 text-1100 fw-semi-bold mb-0 deals-revenue">{{card.revenue}}</p>
                </div>
                <div  v-if="card.company" class="deals-company-agent d-flex flex-between-center">
                    <div class="d-flex">
                        <span class="uil uil-user me-2"><font-awesome-icon :icon="['far', 'fa-building']" /></span>
                        <p class="text-container text-800 fw-bold fs--1 mb-0">{{card.company}}</p>
                    </div>
                </div>
               <!-- <div class="deals-company-agent d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <span class="uil uil-headphones me-2"><font-awesome-icon :icon="['far', 'fa-circle-user']" /></span>
                        <p class="text-800 fw-bold fs--1 mb-0">{{card.responsible}}</p>
                    </div>
                </div>-->
                <div class="collapse" :id="'collapseWidthDeals-' + card.id">
                    <div class="d-flex gap-2">
                        {{card.badges}}
                        <span class="badge badge-phoenix mb-3 badge-phoenix-info">new</span>
                        <!--<span class="badge badge-phoenix mb-3 badge-phoenix-danger">Urgent</span>-->
                    </div>
                    <!--<p class="deals-category fs--1 mb-0 mt-1"><span class="me-1 text-500" data-feather="grid" style="stroke-width:2; height: 12px; width: 12px"><font-awesome-icon :icon="['fas', 'fa-bullseye']" /></span>Campaign #1</p>
                    -->
                    <table class="mb-3 w-100 table-stats">
                        <tr>
                            <th></th>
                        </tr>
                        <tr v-if="card.revenue">
                            <!-- <td class="py-1 d-none d-sm-block pe-sm-2">:</td>-->
                            <td class="py-1">
                                <div class="d-flex align-items-center">
                                    <span class="me-2 text-700" data-feather="dollar-sign" style="width:14px;"><font-awesome-icon :icon="['fas', 'fa-dollar']" /></span>
                                    <p class="fw-semi-bold fs--1 mb-0 text-700">Expected Revenue:</p>
                                </div>
                                <p class="ps-6 ps-sm-0 fw-semi-bold fs--1 mb-0 mb-0 pb-3 pb-sm-0 text-1100">{{card.revenue}}</p>
                            </td>
                        </tr>
                        <tr v-if="card.company">
                            <!--<td class="py-1 d-none d-sm-block pe-sm-2">:</td>-->
                            <td class="py-1">
                                <div class="d-flex align-items-center">
                                    <span class="me-2 text-700" data-feather="user" style="width:14px;"><font-awesome-icon :icon="['far', 'fa-building']" /></span>
                                    <p class="fw-semi-bold fs--1 mb-0 text-700">Company Name:</p>
                                </div>
                                <p class="text-container ps-6 ps-sm-0 fw-semi-bold fs--1 mb-0 mb-0 pb-3 pb-sm-0 text-1100 d-flex align-items-center gap-2">{{card.company}}<a v-if="card.company_linkedin" :href="card.company_linkedin" target="_blank"> <span class="fab fa-whatsapp-square text-700"><font-awesome-icon :icon="['fab', 'fa-linkedin-in']" /></span></a></p>
                            </td>
                        </tr>
                        <tr v-if="card.title">
                            <!--<td class="py-1 d-none d-sm-block pe-sm-2">:</td>-->
                            <td class="py-1">
                                <div class="d-flex align-items-center">
                                    <span class="me-2 text-700" data-feather="calendar" style="width:14px;"><font-awesome-icon :icon="['far', 'fa-flag']" /></span>
                                    <p class="fw-semi-bold fs--1 mb-0 text-700">Position:</p>
                                </div>
                                <p class="text-container ps-6 ps-sm-0 fw-semi-bold fs--1 mb-0 mb-0 pb-3 pb-sm-0 text-1100">{{card.title}}</p>
                            </td>
                        </tr>
                        <tr v-if="card.location">
                            <!--<td class="py-1 d-none d-sm-block pe-sm-2">:</td>-->
                            <td class="py-1">
                                <div class="d-flex align-items-center">
                                    <span class="me-2 text-700" data-feather="calendar" style="width:14px;"><font-awesome-icon :icon="['far', 'fa-map']" /></span>
                                    <p class="fw-semi-bold fs--1 mb-0 text-700">Location:</p>
                                </div>
                                <p class="text-container ps-6 ps-sm-0 fw-semi-bold fs--1 mb-0 mb-0 pb-3 pb-sm-0 text-1100">{{card.location}}</p>
                            </td>
                        </tr>
                        <tr v-if="card.responsible">
                            <!-- <td class="py-1 d-none d-sm-block pe-sm-2">:</td>-->
                            <td class="py-1">
                                <div class="d-flex align-items-center">
                                    <span class="me-2 text-700" data-feather="headphones" style="width:14px;"><font-awesome-icon :icon="['far', 'fa-user']" /></span>
                                    <p class="fw-semi-bold fs--1 mb-0 text-700">Assigned Agent:</p>
                                </div>
                                <p class="ps-6 ps-sm-0 fw-semi-bold fs--1 mb-0 mb-0 pb-3 pb-sm-0 text-1100">{{card.responsible}}</p>
                                <!-- <select class="form-select form-select-sm py-0 ms-n3 border-0 shadow-none">
                                    <option selected="selected">Ally Aagaard</option>
                                    <option>Lonnie Kub</option>
                                </select>-->
                            </td>
                        </tr>
                    </table>
                    <p class="fs--1 mb-1"> Probability:</p>
                    <div class="progress" style="height:8px">
                        <!--bg-info-->
                        <!--<div :class="'progress-bar rounded-pill ' + card.probability.color" role="progressbar" :style="'width:' + card.probability.value + '%'" :aria-valuenow="card.probability.value" aria-valuemin="0" aria-valuemax="100"></div>-->
                        <div class="progress-bar rounded-pill" role="progressbar" :style="'width: ' + getProbabilityPercentage + '%'" :aria-valuenow="getProbabilityPercentage" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'KanbanCard',
        props: {
            card: {
                type: Object,
                required: true
            }
        },
        computed: {
            cardDate() {
                const date = new Date(this.card._ts * 1000);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            },
            cardTime() {
                const date = new Date(this.card._ts * 1000);
                const options = { hour: '2-digit', minute: '2-digit' };
                return date.toLocaleTimeString('en-US', options);
            },
            getProbabilityPercentage() {
                const probability = this.card.probability;
                switch (probability) {
                    case 'lowest-probability':
                        return 20;
                    case 'low-probability':
                        return 40;
                    case 'medium-probability':
                        return 60;
                    case 'high-probability':
                        return 80;
                    case 'highest-probability':
                        return 100;
                    default:
                        return 0; // you can return whatever you deem fit for default case
                }
            }
        },
    }
</script>

<style scoped>
</style>