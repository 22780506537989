<!-- src/components/PasswordRecovery.vue -->
<template>
    <div>
        <!-- Add your password recovery form here -->
        <!-- ===============================================-->
        <!--    Main Content-->
        <!-- ===============================================-->
 
                <div class="container-fluid">
                    <div class="bg-holder bg-auth-card-overlay">
                    </div>
                    <!--/.bg-holder-->
                    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
                        <div class="col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
                            <div class="card border border-300 auth-card">
                                <div class="card-body">
                                    <div class="row align-items-center gx-0 gy-7 pt-2 pb-2">
                                        <div class="col mx-auto">
                                            <div class="auth-form-box">
                                                <div class="text-center">
                                                    <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                                                        <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                                                            <img src="../assets/img/logo-cleverup-logo.png" alt="clever-up" width="80" />
                                                        </div>
                                                    </a>
                                                    <h4 class="text-1000">Forgot your password?</h4>
                                                    <p class="text-700 mb-5">Enter your email below and we will <br class="d-md-none" />send you <br class="d-none d-xxl-block" />a reset link</p>
                                                    <form class="d-flex align-items-center mb-5">
                                                        <input class="form-control flex-1" id="email" type="email" placeholder="Email" />
                                                        <button class="btn btn-primary ms-2">Send<span class="fas fa-chevron-right ms-2"></span></button>
                                                    </form><a class="fs--1 fw-bold" href="#!">Still having problems?</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
   
        <!-- ===============================================-->
        <!--    End of Main Content-->
        <!-- ===============================================-->
    </div>
</template>

<script>
    export default {
        name: 'PasswordRecovery',
    };
</script>