import axios from 'axios';

const API_BASE_URL = 'https://api.cleverup.io';

export default {
    async importLeads(jsonArray) {

        try {
            const token = localStorage.getItem('accessToken');

            const response = await axios.post(`${API_BASE_URL}/api/lead/create`,
                jsonArray,
                {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error('Error sending data to server:', error);
        }
    }
}