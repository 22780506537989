<template>
    <nav class="navbar navbar-vertical navbar-expand-lg">
        <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
            <!-- scrollbar removed-->
            <div class="navbar-vertical-content">
                <ul class="navbar-nav flex-column" id="navbarVerticalNav">
                    <li class="nav-item">
                        <!-- parent pages-->
                        <div class="nav-item-wrapper">
                            <router-link class="nav-link dropdown-indicator label-1" to="/" role="button">
                                <div class="d-flex align-items-center">
                                    <div class="dropdown-indicator-icon"><span class="fas fa-caret-right"></span></div><span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'chart-line']" /></span><span class="nav-link-text">Dashboard</span>
                                </div>
                            </router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <!-- parent pages-->
                        <div class="nav-item-wrapper">
                            <router-link class="nav-link dropdown-indicator label-1" to="/leads" role="button">
                                <div class="d-flex align-items-center">
                                    <div class="dropdown-indicator-icon"><span class="fas fa-caret-right"></span></div><span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'users']" /></span><span class="nav-link-text">Leads</span>
                                </div>
                            </router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <!-- parent pages-->
                        <div class="nav-item-wrapper">
                            <router-link class="nav-link dropdown-indicator label-1" to="/integrations" role="button">
                                <div class="d-flex align-items-center">
                                    <div class="dropdown-indicator-icon"><span class="fas fa-caret-right"></span></div><span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'arrow-right-arrow-left']" /></span><span class="nav-link-text">Integrations</span>
                                </div>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
      <!--  <div class="navbar-vertical-footer">
            <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"><span class="uil uil-left-arrow-to-left fs-0"></span><font-awesome-icon :icon="['fas', 'angles-left']" /><span class="navbar-vertical-footer-text ms-2">Collapsed View</span></button>
        </div>-->
    </nav>
</template>

<script>
      export default {
        name: 'VerticalNavbar',
    };
</script>

<style scoped>
   
</style>