<template>
        <!--<footer class="footer position-absolute">
            <div class="row g-0 justify-content-between align-items-center h-100">
                <div class="col-12 col-sm-auto text-center">-->
                    <!--<p class="mb-0 mt-2 mt-sm-0"><small>Made with <font-awesome-icon class="text-danger" :icon="['fas', 'heart']" /> at Clever Up<span class="d-none d-sm-inline-block"></span><span class="d-none d-sm-inline-block mx-1">|</span><br class="d-sm-none" />2023 &copy;<a class="mx-1" href="https://cleverup.io">Clever Up</a></small></p>-->
                <!--</div>
                <div class="col-12 col-sm-auto text-center">
                    <p class="mb-0 opacity-75"><small>Made with <font-awesome-icon class="text-danger" :icon="['fas', 'heart']" /> at Clever Up<span class="d-none d-sm-inline-block"></span><span class="d-none d-sm-inline-block mx-1">|</span><br class="d-sm-none" />2023 &copy;<a class="mx-1" href="https://cleverup.io">Clever Up</a></small><small>v0.30.5</small></p>
                </div>
            </div>
        </footer>-->
</template>

<script>
    export default {
        name: 'Footer',
    };
</script>

<style scoped>
    /* Add your footer styles here */
</style>